import {
  Box,
  Collapse,
  Flex,
  Group,
  Stack,
  TextInput
} from '@mantine/core'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import FilterViewer from '../../components/filters/FiltersViewer'
import SelectFilter, { SelectFilterChangedEvent } from '../../components/filters/SelectFilter'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { filterCleared, filterRemoved, selectFilterChanged } from '../../redux/peerGroupsSlice'
import { getScreenerFilterOptions } from '../../services'
import camelCaseToCapitalised from '../../utils/camelCaseToCapitalised'
import { convertSnakeToCamelCase } from '../../utils/convertSnakeToCamelCase'

const selectableFilters = [
  { key: 'analyst', multiSelect: true },
  { key: 'primaryVendor', multiSelect: true },
  { key: 'strategy', multiSelect: true },
  { key: 'sectorFocus', multiSelect: true },
  { key: 'status', multiSelect: true },
  { key: 'managementFee', multiSelect: true },
  { key: 'researchStatus', multiSelect: true },
  { key: 'typicalExposureRanges', multiSelect: true },
  { key: 'fundType', multiSelect: true },
  { key: 'gate', multiSelect: true },
  { key: 'redemptionFreq', multiSelect: true },
  { key: 'redemptionNotice', multiSelect: true },
]?.map(it => ({ ...it, name: camelCaseToCapitalised(it.key) }))

const textFieldFilters = [
  { placeholder: 'Name', key: 'name' },
  { placeholder: 'Min Months of Data', key: 'monthsOfData' },
]

const PeerGroupFundScreenerFilter = () => {
  const dispatch = useAppDispatch()
  const open = useAppSelector(state => state.peerGroups.filterOpened)
  const filterOnValues = useAppSelector(state => state.peerGroups.fundScreenerFilters.filterOn)


  const { data: filterOptions } = useQuery(['peer-group-funds-screener-filters-options'], () => getScreenerFilterOptions(), {
    select: (res) => {
      const convertedData = convertSnakeToCamelCase(res?.data)
      Object.keys(convertedData).forEach(key => {
        convertedData[key] = convertedData[key]?.filter(it => !!it)?.map((item: string) => ({ label: item, value: item }))
      })

      return convertedData
    }
  })

  const changeFilterValueHandler = React.useCallback((filterKey: string) => (value: SelectFilterChangedEvent) => dispatch(selectFilterChanged({ key: filterKey, value })), [])
  const removeFilterHandler = (key: string) => dispatch(filterRemoved({ key }))
  const clearAllFilter = () => dispatch(filterCleared())

  const submitTextFilterValueHandler = (key: string) => (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(selectFilterChanged({ key, value: event.target[key].value }))
  }

  return (
    <>
      <Collapse in={open}>
        <Box
          bg="var(--mantine-color-highlightBlue)"
          p="md"
        >
          <Stack gap="sm">
            <Flex gap="sm">
              {
                textFieldFilters.map((filter, idx) => (
                  <form key={idx} onSubmit={submitTextFilterValueHandler(filter.key)}>
                    <TextInput
                      name={filter.key}
                      placeholder={filter.placeholder}
                      min={0}
                      size="xs"
                    />
                  </form>
                ))
              }
            </Flex>
            <Group
              gap="sm"
              justify='flex-start'
            >
              {
                selectableFilters.map((filter, idx) => (
                  <SelectFilter
                    key={idx}
                    multiSelect={filter.multiSelect}
                    onChange={changeFilterValueHandler(filter.key)}
                    options={filterOptions?.[filter.key]}
                    value={filterOnValues[filter.key] as string | string[]}
                  >
                    {filter.name}
                  </SelectFilter>
                ))
              }
            </Group>
            <FilterViewer
              filters={filterOnValues}
              onClickClear={clearAllFilter}
              onClickDeleteFilter={removeFilterHandler}
            />
          </Stack>
        </Box>
      </Collapse >
    </>
  )
}

export default PeerGroupFundScreenerFilter
