import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import { Form, Formik } from "formik"
import React, { useState } from "react"
import { toast } from "react-toastify"
import * as Yup from "yup"

import { Combobox, DatePicker, TextInput } from "../../components/forms"
import MonthlyCalendar from "../../components/misc/monthlyCalendar"
import { fund_detail_rows } from "../../data/funds"
import { useSecurities } from "../../hooks"
import { updateHedgeFund } from "../../services"
import { dateTimeManager as dt } from "../../utils"

const validationSchema = Yup.object().shape({
  analyst: Yup.string().required().label("Analyst"),
  name: Yup.string().required().label("Fund Name"),
  strategy: Yup.string().required().label("Strategy"),
})

const FundForm = ({ saveFund, saved, saving, ...props }) => {
  const { fund_terms } = fund_detail_rows
  let editableFields = fund_terms.filter(
    (o) => !o.disabled && o.field !== "strategy" && o.field !== "inception_date"
  )
  editableFields = editableFields.map(({ field, items, title }) => ({
    items,
    name: field,
    placeholder: title,
  }))
  const initialValues = Object.assign(
    {},
    ...editableFields.map((x) => ({ [x.name]: "" }))
  )
  const { strategy_list: strategyList } = useSecurities()
  return (
    <Formik
      initialValues={{
        inception_date: null,
        name: "",
        strategy: "",
        ...initialValues,
      }}
      onSubmit={saveFund}
      validationSchema={validationSchema}
    >
      {() => (
        <Form className="new-fund-form">
          <TextInput
            name="name"
            placeholder="Fund Name"
          />
          <Combobox
            items={strategyList}
            name="strategy"
            placeholder="Strategy"
          />
          <div className="date-picker-row">
            <span className="label">Inception Date:</span>
            <DatePicker
              calendar={MonthlyCalendar}
              format="MMM yyyy"
              name="inception_date"
              title="Type in your birth date..."
            />
          </div>
          {editableFields.map((info, idx) => {
            const { items } = info || {}
            const Input = items ? Combobox : TextInput
            return <Input
              {...info}
              key={idx}
            />
          })}
          <div className="new-security-submit">
            <Button
              className="submit-button"
              color="primary"
              disableElevation
              disabled={saved || saving}
              size="small"
              type="submit"
              variant="contained"
            >
              {saved
                ? "Saved. Refresh the page for the fund to appear"
                : "Save"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

// These fields must be explicitly set to null if they have no value
// because the backend requires them to be null rather than undefined or omitted
const NULLABLE_FIELDS = ['management_fee', 'redemption_fee', 'performance_fee'];

export default function NewFundForm({ switchMode, ...props }) {
  const [saved, setSaved] = useState(false)
  const [saving, setSaving] = useState(false)

  const { search_list: searchList } = useSecurities()

  const saveFund = async (data, { resetForm }) => {
    setSaving(true)
    const existing = searchList.find((o) => o.name === data.name)
    if (existing) {
      toast.info("The fund already exists!", {
        position: toast.POSITION.BOTTOM_CENTER,
      })
      return
    }

    const { inception_date, ...restData } = data || {}

    const nonNullableData = NULLABLE_FIELDS.reduce((acc, field) => {
      acc[field] = restData[field] || null;
      return acc;
    }, {})

    const payload = {
      ...restData,
      inception_date: dt.jsToDb(inception_date),
      ...nonNullableData
    }

    try {
      await updateHedgeFund(payload)
      resetForm()
      setSaved(true)
    } catch (e) {
      toast.warning(
        "The fund has not been saved. Please contact system administrator",
        {
          position: toast.POSITION.BOTTOM_CENTER,
        }
      )
    }
    setSaving(false)
  }

  return (
    <div className="security-setup-container">
      <div className="title">
        <Typography
          align="center"
          variant="h6"
        >
          New Fund
        </Typography>
        <Button
          className="set-up-mode-button"
          color="primary"
          disableElevation
          onClick={switchMode}
          size="small"
          startIcon={<CloseIcon />}
          variant="contained"
        >
          Cancel
        </Button>
      </div>
      <FundForm
        saveFund={saveFund}
        saved={saved}
        saving={saving}
      />
    </div>
  )
}
