import { configureStore } from '@reduxjs/toolkit'

import aaapReducer from './aaapSlice'
import docsNotificationReducer from './docsNotificationSlice'
import fofShareClassesReducer from './fofShareClassesSlice'
import hedgeFundsReducer from './hedgeFundsSlice'
import marketIndicesReducer from './marketIndicesSlice'
import peerGroupsReducer from './peerGroupsSlice'
import portfoliosReducer from './portfoliosSlice'
import securitySharedReducer from './securitySharedSlice'
import webfoliosReducer from './webfoliosSlice'

export const store = configureStore({
  reducer: {
    aaap: aaapReducer,
    docsNotification: docsNotificationReducer,
    fofShareClasses: fofShareClassesReducer,
    hedgeFunds: hedgeFundsReducer,
    marketIndices: marketIndicesReducer,
    peerGroups: peerGroupsReducer,
    portfolios: portfoliosReducer,
    securityShared: securitySharedReducer,
    webfolios: webfoliosReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch