import React, { useMemo } from "react";
import { useField, useFormikContext } from "formik";
import ErrorMessage from "./ErrorMessage";
import MuiDatePicker from "./MuiDatePicker";

export default function DatePicker({ name, ...props }) {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext()

  const handleChange = (date) => {
    setFieldValue(name, date ? date.toISOString() : null);
  };

  return (
    <>
      <MuiDatePicker
        {...field}
        format="dd MMM yyyy"
        disableToolbar={false}
        {...props}
        onChange={handleChange}
        placeholder={props.format}
      />

      {meta.touched && meta.error && <ErrorMessage error={meta.error} />}
    </>
  );
}

export const BulkDatePicker = ({ name, bulkArray, bulkField, ...props }) => {
  const { values, setValues } = useFormikContext();
  const value = values[name] || "";

  const bulkChange = (newDate, name) => {
    const { [bulkArray]: modArray } = values;
    let newArray = [...modArray];

    for (let i = 0; i < newArray.length; i++) {
      let element = newArray[i];
      element[bulkField] = newDate;
    }
    setValues({ ...values, [bulkArray]: newArray, [name]: newDate });
  };

  return (
    <MuiDatePicker
      value={value}
      onChange={(e) => {
        bulkChange(e, name);
      }}
      format="dd MMM yyyy"
      disableToolbar={false}
      {...props}
    />
  )
};
