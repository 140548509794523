import React, { useContext, useMemo } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'

import ScrollToTop from '../components/misc/ScrollToTop'
import { accessRightKeyMap } from '../constants/permissions'
import { PRIVATE_MARKET_BASE_PATH, PRIVATE_MARKETS_PATH } from '../constants/routes.constants'
import { AuthContext } from '../contexts'
import useFetchFactSheetNotifications from '../hooks/useFetchFactSheetNotification'
import useMixpanelPageTrack from '../hooks/useMixpanelPageTrack'
import routerHistory from '../resources/history'
import publicRoutes from '../resources/publicRoutes'
import {
  aaap_routes,
  analytics_routes,
  data_management_routes,
  ir_routes,
  ops_routes,
  privateEquityRoutes,
  user_management_routes,
} from '../resources/routes'
import Navigation from './navigation/navigation'
import NotFoundPage from './routes/NotFoundPage'
import SecurityHolder from './securityHolder'

const platform_name = 'Antarctica Hub'

const authRoutesMap = {
  [accessRightKeyMap.hasAccessUserManagement]: user_management_routes,
  [accessRightKeyMap.hasAccessAnalytics]: analytics_routes,
  [accessRightKeyMap.hasAccessDataManagement]: data_management_routes,
  [accessRightKeyMap.hasAccessOps]: ops_routes,
  [accessRightKeyMap.hasAccessIr]: ir_routes,
}

const AuthRoutes = () => {
  useMixpanelPageTrack()
  useFetchFactSheetNotifications()
  const { logout, profile } = useContext(AuthContext)
  const { permission_list: permissionList } = profile
  const routes = useMemo(() => {
    const authRoutes = [...aaap_routes, ...privateEquityRoutes]

    Object.entries(authRoutesMap).forEach(([key, routes]) => {
      if (permissionList?.includes(key)) {
        authRoutes.push(...routes)
      }
    })

    return authRoutes
  }, [permissionList])

  return (
    <Navigation>
      <SecurityHolder />
      <Switch>
        {routes.map(({ component: Component, title, ...rest }, i) => (
          <Route
            key={i}
            {...rest}
            render={(routeProps) => {
              const doc_title =
                title && platform_name
                  ? `${title} | ${platform_name}`
                  : `${platform_name}`
              document.title = doc_title
              return (
                <Component
                  {...routeProps}
                  {...rest}
                  platform_name={platform_name}
                />
              )
            }}
          />
        ))}
        <Route component={NotFoundPage} />
        <Redirect
          exact
          from={`${PRIVATE_MARKET_BASE_PATH}/funds`}
          to={PRIVATE_MARKETS_PATH}
        />
        <Redirect to="/" />
      </Switch>
    </Navigation>
  )
}

const PublicRoutes = () => {
  useMixpanelPageTrack()
  return (
    <Switch>
      {publicRoutes.map(({ component: Component, title, ...rest }, i) => (
        <Route
          key={i}
          {...rest}
          render={(routeProps) => {
            const doc_title = `${title} | ${platform_name}`
            document.title = doc_title
            return <Component
              {...routeProps}
              {...rest}
            />
          }}
        />
      ))}
    </Switch>
  )
}

export default function App() {
  const { isAuthorized } = useContext(AuthContext)

  return (
    <Router history={routerHistory}>
      <ScrollToTop>
        {isAuthorized ? <AuthRoutes /> : <PublicRoutes />}
      </ScrollToTop>
    </Router>
  )
}
