import {
  Box,
  Center,
  Group,
  Loader,
  Pagination,
  Stack,
  Table,
  Tooltip
} from '@mantine/core'
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react'
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import { FilterFn, flexRender } from '@tanstack/react-table'
import { Table as TanstackTable } from "@tanstack/table-core"
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'

export interface PaginationProps {
  activePage: number
  onPageChange: (page: number) => void
  total?: number
}

interface DataTableProps<T> {
  table: TanstackTable<T>
  onClickRow?: (data: T, index?: number) => void
  activeRow?: number | null
  activeRowIdType?: 'rowIndex' | string
  paginationProps?: PaginationProps
  isLoading?: boolean;
  pinnedColumns?: string[]
  fullWidth?: boolean
}

declare module '@tanstack/table-core' {
  interface FilterFns {
    filterByTags: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)
  console.log('itemRank', itemRank)
  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

function OverflowTooltipCell({ children }: { children: React.ReactNode }) {
  const [isOverflow, setIsOverflow] = useState(false)
  const textRef = useRef(null)

  useEffect(() => {
    if (textRef.current) {
      const overflow = textRef.current.scrollWidth > textRef.current.clientWidth
      setIsOverflow(overflow)
    }
  }, [children])

  return isOverflow ? (
    <Tooltip
      label={children}
      withArrow
    >
      <div
        ref={textRef}
        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
      >
        {children}
      </div>
    </Tooltip>
  ) : (
    <div
      ref={textRef}
      style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
    >
      {children}
    </div>
  )
}

function DataTable<T>({
  activeRow,
  activeRowIdType = 'rowIndex',
  isLoading,
  onClickRow,
  paginationProps,
  table,
  pinnedColumns,
  fullWidth,
}: DataTableProps<T>) {
  const tableRef = useRef<HTMLDivElement>(null);
  const clickRowHandler = (data: T, rowIdx: number) => () => {
    if (onClickRow) {
      onClickRow(data, rowIdx)
    }
  }

  const isPinnedColumn = (columnId: string, index: number) => {
    if (pinnedColumns?.includes('select') && index === 0) return true;
    if (pinnedColumns?.includes('name') && index === 1) return true;
    return false;
  };

  const getPinnedPosition = (index: number) => {
    if (index === 0) return 0;
    if (index === 1) return table.getCenterHeaderGroups()[0]?.headers[0]?.getSize() || 0;
    return 'auto';
  };


  return (
    <>
      <Stack
        style={{ overflow: 'auto' }}
        className={clsx({ 'filled-datatable': fullWidth })}
      >
        <Box
          ref={tableRef}
          style={{ overflow: isLoading ? 'hidden' : 'auto' }}
          w="100%"
        >
          <Table style={{ overflow: 'scroll' }}>
            <Table.Thead style={{ position: 'sticky', top: 0, zIndex: 3, background: 'var(--mantine-color-iceBlue)' }}>
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.Tr key={headerGroup.id}>
                  {headerGroup.headers?.map((header, index) => (
                    <Table.Th
                      colSpan={header.colSpan}
                      key={header.id}
                      style={{
                        maxWidth: `${header.getSize()}px`,
                        minWidth: `${header.getSize()}px`,
                        position: isPinnedColumn(header.id, index) ? 'sticky' : 'static',
                        left: getPinnedPosition(index),
                        zIndex: isPinnedColumn(header.id, index) ? 2 : 1,
                        background: 'var(--mantine-color-iceBlue)',
                      }}
                    >
                      <Box
                        component="div"
                        onClick={header.column.getToggleSortingHandler()}
                        style={{ cursor: header.column.getCanSort() ? 'pointer' : 'default' }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <Box
                          display="inline-block"
                          opacity={header.column.getIsSorted() ? 1 : 0}
                        >
                          {header.column.getIsSorted() === 'asc' ? <IconArrowUp size={14} /> : <IconArrowDown size={14} />}
                        </Box>
                      </Box>
                    </Table.Th>
                  ))}
                </Table.Tr>
              ))}
            </Table.Thead>
            {
              !isLoading && (
                <Table.Tbody>
                  {table.getRowModel()?.rows?.map((row, rowIndex) => (
                    <Table.Tr
                      className={clsx({
                        'active-table-row': activeRow === (activeRowIdType === 'rowIndex' ? row.index : row.original[activeRowIdType]),
                        'clickable-row': !!onClickRow,
                        'selected-row': row.getIsSelected()
                      })}
                      style={{
                        backgroundColor: rowIndex % 2 === 0 ? '#f0f8ff' : 'white', // Light blue for even rows, white for odd
                      }}
                      key={row.id}
                      onClick={clickRowHandler(row.original, row.index)}
                    >
                      {
                        row.getVisibleCells().map((cell, index) => (
                          <Table.Td
                            key={cell.id}
                            style={{
                              maxWidth: cell.column.getSize(),
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              position: isPinnedColumn(cell.column.id, index) ? 'sticky' : 'static',
                              left: getPinnedPosition(index),
                              zIndex: isPinnedColumn(cell.column.id, index) ? 2 : 1,
                              background: rowIndex % 2 === 0 ? '#f0f8ff' : 'white', // Match row background
                            }}
                          >
                            <OverflowTooltipCell>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </OverflowTooltipCell>
                          </Table.Td>
                        ))
                      }
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              )
            }
          </Table>
        </Box>
        {
          isLoading && (
            <Center py={200}>
              <Loader />
            </Center>
          )
        }
      </Stack>
      {
        !!paginationProps?.total &&
        <Group justify="center" style={{ position: 'sticky', bottom: 0, left: 0, right: 0, zIndex: 10 }} pt="xs" bg="white" w="100%">
          <Pagination
            onChange={paginationProps.onPageChange}
            total={paginationProps.total}
            value={paginationProps.activePage}
            withEdges
          />
        </Group>
      }
    </>
  )
}


export default DataTable
