import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { GridToolbar } from '@progress/kendo-react-grid'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { PortfolioTable } from '../../components'
import { useSecurities } from '../../hooks'
import useAccessRights from '../../hooks/useAccessRights'

const IndexCell = (props) => {
  const { dataItem } = props
  const { index, link } = dataItem || {}
  if (link) {
    return (
      <td>
        <Link target="_blank" to={link}>
          {index}
        </Link>
      </td>
    )
  }
  return <td>{index}</td>
}

export default function PraTable({
  loading,
  internal_analysis,
  is_fof_share_class,
  pra_data,
  short,
  table_props,
}) {
  const { hasAccessInternalClient } = useAccessRights()
  const { search_list_map: searchListMap } = useSecurities()
  const [currentView, setCurrentView] = useState('Performance')

  const views = [
    'Allocation (1st)',
    'Allocation (Last)',
    'Performance',
    'Attribution',
  ]
  let report
  const active_views = short ? ['Performance'] : views
  if (pra_data) {
    report = pra_data || {}
  } else {
    ;({ report = {} } = internal_analysis || {})
  }
  const data = report[currentView]
  const { Meta: meta } = report || {}
  if (meta) {
    data.forEach((entry) => {
      const security = meta.find((o) => o.name === entry.index)
      const { fund_id } = security || {}
      const hasSecurityAndAccessToSecurity =
        !!security && !!searchListMap?.[fund_id]
      if (hasSecurityAndAccessToSecurity) {
        entry.link = `/asset-detail/${fund_id}/`
      }
    })
  }
  const show_ytd = currentView === 'Attribution'
  const show_years = currentView === 'Performance'
  return (
    <div className="pra-table">
      <PortfolioTable
        key={data?.length}
        loading={loading}
        data={data}
        index_col_props={{ cell: IndexCell }}
        negative_red
        show_years={show_years}
        total_col={show_ytd}
        {...table_props}
      >
        <GridToolbar>
          <ButtonGroup>
            {active_views.map((view, i) => {
              const color = view === currentView ? 'primary' : 'default'
              return (
                <Button
                  color={color}
                  key={i}
                  onClick={() => {
                    setCurrentView(view)
                  }}
                  variant="contained"
                >
                  {view}
                </Button>
              )
            })}
          </ButtonGroup>
        </GridToolbar>
      </PortfolioTable>
    </div>
  )
}
