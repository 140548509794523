export const screener_fields = {
  performance: [
    {
      field: 'strategy',
      title: 'Strategy',
      width: '200px',
    },
    {
      field: 'notes',
      title: 'Focus',
      width: '225px',
    },
    {
      field: 'calculated_statistics.stats_date',
      title: 'As of date',
    },
    {
      field: 'calculated_statistics.last_month_ret',
      title: 'MTD',
    },
    {
      field: 'calculated_statistics.ytd',
      title: 'YTD',
    },
    // {
    //   field: "calculated_statistics.three_month_ret",
    //   title: "3M",
    // },
    // {
    //   field: "calculated_statistics.six_month_ret",
    //   title: "6M",
    // },
    // {
    //   field: "calculated_statistics.twelve_month_ret",
    //   title: "12M",
    // },
    // {
    //   field: "calculated_statistics.three_years_ret",
    //   title: "3Y Ann",
    // },
    // {
    //   field: "calculated_statistics.five_years_ret",
    //   title: "5Y Ann",
    // },
    {
      field: 'calculated_statistics.ann_ret',
      title: 'Ann. Ret',
    },
    {
      field: 'calculated_statistics.ann_vol',
      title: 'Ann. Vol',
    },
    {
      field: 'calculated_statistics.sharpe',
      title: 'Sharpe',
    },
  ],
  risk: [
    {
      field: 'calculated_statistics.positive_months',
      title: 'Pos. Months',
    },
    {
      field: 'calculated_statistics.win_ratio',
      title: 'Win Ratio',
    },
    {
      field: 'calculated_statistics.avg_win',
      title: 'Avg Win',
    },
    {
      field: 'calculated_statistics.avg_loss',
      title: 'Avg Loss',
    },
    {
      field: 'calculated_statistics.gain_dev',
      title: 'Gain Dev.',
    },
    {
      field: 'calculated_statistics.loss_dev',
      title: 'Loss Dev.',
    },
    {
      field: 'calculated_statistics.gain_to_loss_dev',
      title: 'Gain/Loss Dev.',
    },
    {
      field: 'calculated_statistics.largest_win',
      title: 'Lrgst Win',
    },
    {
      field: 'calculated_statistics.largest_loss',
      title: 'Lrgst Loss',
    },
    {
      field: 'calculated_statistics.max_dd',
      title: 'Max Drawdown',
    },
    {
      field: 'calculated_statistics.kurt',
      title: 'Kurtosis',
    },
    {
      field: 'calculated_statistics.skew',
      title: 'Skewness',
    },
  ],
}

export const screener_button_list = [
  { id: 'performance', title: 'Performance' },
  { id: 'risk', title: 'Stats' },
]

export const screener_mode_button_list = [
  { id: 'aaap', title: 'AAAP' },
  { id: 'funds', title: 'Funds' },
]

export const strategies = [
  'Portfolio Gross Attribution',
  'Commodities',
  'Long/Short Equity',
  'Statistical Arbitrage',
  'Volatility - Equity - Relative Value',
  'Volatility - Equity - Relati...',
  'Special Situations',
  'Multi Strategy',
  'Multi-Strategy - Macro',
  'Global Macro - Relative Value',
  'Global Macro - Relative Valu...',
  'Global Macro',
  'Event Driven',
  'Credit',
  'Multi-Strategy - Relative Value – Fixed Income',
  'Multi-Strategy - Relative Va...',
  'Fixed Income Arbitrage',
  'Distressed',
  'Other',
  'RVF Equities',
  'Structured Credit',
  'Side Pocket',
  'Tail Risk',
  'Fund of Funds',
  'Long Equities',
  'Volatility Arbitrage',
  'Convertible Arbitrage',
  'Cash Equivalent',
  'CTA',
  'Macro',
  'Long Short Equities',
  'Long/Short Credit',
  'Multi-Strategy',
  'CTA/Managed Futures',
  'Bottom-Up',
  'Fixed Income',
  'Investable Index',
  'Arbitrage',
  'Distressed Debt',
  'Others',
  'Relative Value',
  'Top-Down',
  'Dual Approach',
  'Diversified Debt',
  'Value',
  'Long Only Absolute Return',
  'Quant Equities',
  'Equity long/short',
  'RV',
  'Quant Macro',
  'Quant Credit',
  'Stat Arb  /Quant Strategies',
  'Total',
]
// The above - sorted alphabetically
const sortder_strategies = [
  'Arbitrage',
  'Bottom-Up',
  'CTA',
  'CTA/Managed Futures',
  'Cash Equivalent',
  'Commodities',
  'Convertible Arbitrage',
  'Credit',
  'Distressed',
  'Distressed Debt',
  'Diversified Debt',
  'Dual Approach',
  'Equity long/short',
  'Event Driven',
  'Fixed Income',
  'Fixed Income Arbitrage',
  'Fund of Funds',
  'Global Macro',
  'Global Macro - Relative Valu...',
  'Global Macro - Relative Value',
  'Investable Index',
  'Long Equities',
  'Long Only Absolute Return',
  'Long Short Equities',
  'Long/Short Credit',
  'Long/Short Equity',
  'Macro',
  'Multi Strategy',
  'Multi-Strategy',
  'Multi-Strategy - Macro',
  'Multi-Strategy - Relative Va...',
  'Multi-Strategy - Relative Value – Fixed Income',
  'Other',
  'Others',
  'Portfolio Gross Attribution',
  'Quant Credit',
  'Quant Equities',
  'Quant Macro',
  'RV',
  'RVF Equities',
  'Relative Value',
  'Side Pocket',
  'Special Situations',
  'Stat Arb  /Quant Strategies',
  'Statistical Arbitrage',
  'Structured Credit',
  'Tail Risk',
  'Top-Down',
  'Total',
  'Value',
  'Volatility - Equity - Relati...',
  'Volatility - Equity - Relative Value',
  'Volatility Arbitrage',
]

export const db_strategies_june_2024 = [
  'Arbitrage',
  'Bottom-Up',
  'Buyout',
  'Cash Equivalent',
  'Commodities',
  'Convertible Arbitrage',
  'Credit',
  'Crypto',
  'CTA',
  'CTA/Managed Futures',
  'Distressed',
  'Distressed Debt',
  'Diversified Debt',
  'Dual Approach',
  'Equity long/short',
  'Event Driven',
  'Fixed Income',
  'Fixed Income Arbitrage',
  'Fund of Funds',
  'Global Macro',
  'Global Macro - Relative Value',
  'Investable Index',
  'Long Equities',
  'Long Only Absolute Return',
  'Long/Short Credit',
  'Long Short Equities',
  'Long/Short Equity',
  'Macro',
  'Multi Strategy',
  'Multi-Strategy',
  'Multi-Strategy - Macro',
  'Multi-Strategy - Relative Value – Fixed Income',
  // 'nan',
  // 'None',
  'Other',
  'Others',
  'Private Credit',
  'Quant Credit',
  'Quant Equities',
  'Quant Macro',
  'Relative Value',
  'Relative Value - Fixed Income',
  'RV',
  'RVF Equities',
  'Secondary',
  'Side Pocket',
  'Special Situations',
  'Statistical Arbitrage',
  'Structured Credit',
  'Tail Risk',
  'Top-Down',
  'Value',
  'Volatility Arbitrage',
  'Volatility - Equity - Relative Value',
]

export const fund_detail_rows = {
  fund_terms: [
    {
      field: 'underlying_principal_name',
      title: 'Principal(s)',
      disabled: true,
    },
    { field: 'underlying_aum', title: 'Firm AUM', disabled: true },
    { field: 'latest_nav', title: 'NAV', disabled: true },
    {
      field: 'underlying_management_fee',
      title: 'Underlying Management Fee',
      disabled: true,
    },
    {
      field: 'underlying_performance_fee',
      title: 'Underlying Performance Fee',
      disabled: true,
    },
    {
      field: 'analyst',
      title: 'Analyst',
      isUnderlyingFundField: true,
      items: [
        'Aram Darakchian',
        'Atsede Aemro-Selassie',
        'Idan Shani',
        'Rana Atie',
        'Robertson Reid',
        'Kacper Micek',
        'Ashil Doorga',
        'Maria Sfika',
      ],
    },
    {
      field: 'odd_analyst',
      title: 'ODD Analyst',
      isUnderlyingFundField: true,
      items: ['Kenneth MacDonald', 'Virginie Kolesnikov'],
    },
    { field: 'latest_aum', title: 'AUM', isUnderlyingFundField: true },
    {
      field: 'typical_exposure_ranges',
      isUnderlyingFundField: true,
      title: 'Typical Exposure Ranges',
    },
    {
      field: 'strategy',
      isUnderlyingFundField: true,
      title: 'Strategy',
      items: [],
    },
    { field: 'style', isUnderlyingFundField: true, title: 'Style' },
    { field: 'notes', title: 'Notes' },
    {
      field: 'geo_focus',
      title: 'Geo Focus',
      isUnderlyingFundField: true,
    },
    {
      field: 'sector_focus',
      title: 'Sector Focus',
    },
    {
      field: 'inception_date',
      title: 'Inception Date',
    },
    {
      field: 'principal_name',
      title: 'Principal(s)',
    },
    {
      field: 'research_status',
      title: 'Research Status',
      isUnderlyingFundField: true,
      items: [
        'Redeemed',
        'Liquidated',
        'Approved',
        'Sourcing - Preliminary DD',
        'Sourcing - Full DD',
        'Invested - Monitoring',
        'Sourcing - Approval Committee',
        'Sourcing - Intro',
        'Invested - Watch list',
      ].sort(),
    },
    {
      field: 'status',
      title: 'Status',
      items: ['Closed', 'Open', 'Soft-Closed'],
    },
    {
      field: 'portal_address',
      title: 'Portal Address',
    },
    {
      field: 'portal_login',
      title: 'Portal Login',
    },
    {
      field: 'portal_password',
      title: 'Portal Password',
    },
    {
      field: 'min_investment',
      title: 'Minimum Investment',
      format: 'n0',
    },
    {
      field: 'min_additional_investment',
      title: 'Minimum Additional Investment',
      format: 'n0',
    },
    {
      field: 'currency',
      title: 'Currency',
    },
    {
      field: 'management_fee',
      title: 'Management Fee',
    },
    {
      field: 'performance_fee',
      title: 'Performance Fee',
    },
    {
      field: 'cost',
      title: 'Cost',
    },
    {
      field: 'hurdle',
      title: 'Hurdle Rate',
    },
    {
      field: 'high_watermark',
      title: 'High Watermark',
    },
    {
      field: 'subs_freq',
      title: 'Subscription Frequency',
    },
    {
      field: 'subs_notice',
      title: 'Subscription Notice',
    },
    {
      field: 'redemption_freq',
      title: 'Redemption Frequency',
    },
    {
      field: 'redemption_notice',
      title: 'Redemption Notice',
    },
    {
      field: 'redemption_fee',
      title: 'Redemption Fee',
    },
    {
      field: 'gate',
      title: 'Gate',
    },

    {
      field: 'lockup',
      title: 'Lockup',
    },
    {
      field: 'isin',
      title: 'ISIN',
    },
    // {
    // 	field: "prorated",
    // 	title: "Prorated"
    // },
    {
      field: 'investment_manager',
      title: 'Investment Manager',
      isUnderlyingFundField: true,
    },
    {
      field: 'custodian',
      title: 'Custodian',
      isUnderlyingFundField: true,
    },
    {
      field: 'administrator',
      title: 'Administrator',
      isUnderlyingFundField: true,
    },
  ],
  contact_list: [
    {
      field: 'investment_manager',
      title: 'Investment Manager',
    },
    {
      field: 'custodian',
      title: 'Custodian',
    },
    {
      field: 'administrator',
      title: 'Administrator',
    },
  ],
}

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const performance_col_list = [...months, 'YTD']

export const contact_list = [
  { field: 'contact_name', title: 'Name' },
  { field: 'contact_phone', title: 'Phone' },
  { field: 'contact_email', title: 'Email' },
  { field: 'contact_address', title: 'Address' },
  { field: 'contact_position', title: 'Positions' },
]
const lmh_list = ['Low', 'Medium', 'High']
export const strategy_list = [
  'Long/Short Equity',
  'Global Macro - Relative Value',
  'Multi Strategy',
  'Commodities',
  'Credit',
  'Fixed Income Arbitrage',
  'Global Macro',
  'Statistical Arbitrage',
  'Volatility - Equity - Relative Value',
  'Distressed',
  'Multi-Strategy - Relative Value – Fixed Income',
  'Event Driven',
]

export const available_filters = [
  {
    name: 'last_month_ret',
    title: 'Latest Return',
    type: 'slider',
    format: 'percent',
    min: 0,
    max: 1,
    step: 0.05,
  },
  {
    name: 'twelve_month_ret',
    title: '12M Return',
    type: 'slider',
    format: 'percent',
    min: 0,
    max: 1,
    step: 0.05,
  },
  {
    name: 'three_years_ret',
    title: '3YR Return',
    type: 'slider',
    format: 'percent',
    min: 0,
    max: 1,
    step: 0.05,
  },
  {
    name: 'ann_ret',
    title: 'Ann Return',
    type: 'slider',
    format: 'percent',
    min: 0,
    max: 1,
    step: 0.05,
  },
  {
    name: 'ann_vol',
    title: 'Ann Vol',
    type: 'slider',
    format: 'percent',
    min: 0,
    max: 1,
    step: 0.05,
  },
  {
    name: 'sharpe',
    title: 'Sharpe Ratio',
    type: 'slider',
    format: 'number',
    min: 0,
    max: 5,
    step: 0.1,
  },
  {
    name: 'sortino',
    title: 'Sharpe Ratio',
    type: 'slider',
    format: 'number',
    min: 0,
    max: 5,
    step: 0.1,
  },
  {
    name: 'positive_months',
    title: '% of + Months',
    type: 'slider',
    format: 'percent',
    min: 0,
    max: 1,
    step: 0.05,
  },
  {
    name: 'largest_win',
    title: 'Largest Win',
    type: 'slider',
    format: 'percent',
    min: 0,
    max: 1,
    step: 0.05,
  },
  {
    name: 'largest_loss',
    title: 'Largest Loss',
    type: 'slider',
    format: 'percent',
    min: 0,
    max: 1,
    step: 0.05,
  },
  {
    name: 'max_dd',
    title: 'Max Drawdown',
    type: 'slider',
    format: 'percent',
    min: -0.5,
    max: 0,
    step: 0.05,
  },
  {
    name: 'credit_correlation',
    label: 'Credit Correlation',
    type: 'qual_class',
    items: lmh_list,
  },
  {
    name: 'directionality',
    label: 'Directionality',
    type: 'qual_class',
    items: lmh_list,
  },
  {
    name: 'equity_correlation',
    label: 'Equity Correlation',
    type: 'qual_class',
    items: lmh_list,
  },
  {
    name: 'leverage',
    label: 'Leverage',
    type: 'qual_class',
    items: lmh_list,
  },
  {
    name: 'volatility',
    label: 'Volatility',
    type: 'qual_class',
    items: lmh_list,
  },
  {
    name: 'class_name',
    label: 'Class',
    type: 'qual_top_level',
  },

  {
    name: 'strategy',
    label: 'Strategy',
    type: 'qual_top_level',
    items: [],
  },
]
