import SearchIcon from '@material-ui/icons/Search'
import { groupBy } from '@progress/kendo-data-query'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { MultiSelect } from '@progress/kendo-react-dropdowns'
import clsx from 'clsx'
import React, { useState } from 'react'

import { AAM_PRIVATE_MARKET_NAME } from '../../constants/service.constants'
import { useMixpanel } from '../../hooks/useMixpanel'
import router from '../../resources/history'

const page_size = 10

const headings = [
  'Webfolio',
  'FoF',
  'FoF Share Class',
  'User',
  'Bloomberg',
  'Eurekahedge',
  'AAAP Cell',
  'Market Index',
  'Fund',
  'Portfolio',
  'Backfill',
  AAM_PRIVATE_MARKET_NAME,
].sort((a, b) => a.localeCompare(b))

function contains(target, pattern) {
  if (!target) {
    return false
  }
  var value = 0
  pattern.forEach(function (word) {
    value = value + target.toLowerCase().includes(word.toLowerCase())
  })
  return value === pattern.length
}

export const smartSearch = (pattern, list, fields) => {
  let exactResult = list.filter((o) => o.name === pattern)
  if (exactResult.length === 1) {
    return exactResult
  }
  const searchList = pattern.split(' ').filter((o) => o !== '')
  let filtered = list.filter((o) => {
    return fields.map((field) => contains(o[field], searchList)).includes(true)
  })
  return filtered
}

export default ({
  allowCustom = false,
  disabled = false,
  isToolbar = false,
  items = [],
  multi = false,
  name = '',
  noLink,
  onChange,
  placeholder = '',
  searchFields = ['name'],
  value = '',
  ...props
}) => {
  const { mpTrack } = useMixpanel()
  const [skip, setSkip] = useState(0)
  const [take, setTake] = useState(page_size)
  const [filterValue, setFilterValue] = useState('')

  const onFilterChange = ({ filter }) => {
    const { value } = filter || {}
    setFilterValue(value)
    setSkip(0)
    setTake(page_size)
  }

  const pageChange = (e) => {
    setSkip(e.page.skip)
    setTake(e.page.take)
  }

  const onStateChange = (e) => {
    if (onChange) {
      if (onChange && e.value) {
        mpTrack({
          eventName: 'Select Global Search Result',
          properties: {
            Name: e.value.name,
            'Search Input': e.value,
            'Search Type': e.value.type,
          },
        })
      }

      onChange(e)
    }
  }

  const unwindGroup = (grouped) => {
    let result = []
    for (let i = 0; i < grouped.length; i++) {
      const element = grouped[i]
      result.push({ name: element.value })
      for (let j = 0; j < element.items.length; j++) {
        const entry = element.items[j]
        result.push(entry)
      }
    }
    return result
  }

  const linkToDetails = (e, id, onClick) => {
    router.push(`/asset-detail/${id}/`)
    onStateChange(e)
    onClick(e)
  }

  const noLinkClick = (e, onClick) => {
    onClick(e)
  }

  const linkToPe = (e, onClick, id) => {
    router.push(`/private-markets/funds/${id}`)
    onClick(e)
  }

  const itemRender = (li, itemProps) => {
    const { dataItem } = itemProps || {}
    const { id, name } = dataItem || {}
    const is_heading = headings.indexOf(name) !== -1
    const bold_cls = is_heading ? 'selector-group-name' : ''
    const itemChildren = <span className={bold_cls}>{li.props.children}</span>
    let li_props = { ...li.props }
    const { onClick } = li_props
    if (is_heading) {
      li_props.onClick = () => { }
      li_props.className = 'k-item selector-group-name k-state-disabled'
    } else if (noLink) {
      li_props.onClick = (e) => noLinkClick(e, onClick)
    } else if (dataItem.type === AAM_PRIVATE_MARKET_NAME) {
      li_props.onClick = (e) => linkToPe(e, onClick, dataItem.id)
    } else {
      li_props.onClick = (e) => linkToDetails(e, id, onClick)
    }
    return React.cloneElement(li, li_props, itemChildren)
  }

  const sortVendors = (a, b) => {
    if (headings.indexOf(a.value) < headings.indexOf(b.value)) {
      return -1
    }
    if (headings.indexOf(a.value) > headings.indexOf(b.value)) {
      return 1
    }
    return 0
  }

  const filtered_data = smartSearch(filterValue, items, searchFields)

  const grouped_data = groupBy(filtered_data, [{ field: 'type' }])?.filter(group => group.value !== null)

  const final_data = unwindGroup(grouped_data.sort(sortVendors))
  const total = final_data.length
  const Select = multi ? MultiSelect : ComboBox

  return (
    <div
      className={clsx({
        'custom-select-input': !isToolbar,
        'search-input': isToolbar,
      })}
    >
      <Select
        allowCustom={allowCustom}
        className="kendo-virtual-combobox global-search"
        data={final_data.slice(skip, skip + take)}
        disabled={disabled}
        filterable={true}
        itemRender={itemRender}
        name={name}
        onChange={onStateChange}
        onFilterChange={onFilterChange}
        onPageChange={pageChange}
        placeholder={placeholder}
        popupSettings={{
          height: '250px',
        }}
        style={{ width: '100%' }}
        value={value}
        virtual={{
          pageSize: page_size,
          skip: skip,
          total: total,
        }}
        {...props}
      />
      {isToolbar && <SearchIcon className="search-input-icon" />}
    </div>
  )
}
