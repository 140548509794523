import LinearProgress from '@material-ui/core/LinearProgress'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { KendoDropDown } from '../../components'
import { useSecurities } from '../../hooks'
import { getPortfolioAnalysis } from '../../services'
import { PraTable } from '../portfolioAnalysis'

const YearRow = styled.div`
  display: flex;
  justify-content: flex-end;
`

export default function PortfolioAnalysis({ security_details }) {
  const [analysis, setAnalysis] = useState(null)
  const {
    id,
    portfolio_analysis,
    returns_table = [],
    portfolio_type,
  } = security_details
  const short =
    ['AAAP Portfolio', 'AAAP Model Portfolio'].indexOf(portfolio_type) !== -1

  const { fof_share_class_list } = useSecurities()
  const is_fof_share_class = fof_share_class_list?.find((o) => o.id === id)

  const defaultYear = new Date().getFullYear()
  const years = returns_table.map((o) => o.year)
  const [currentYear, setCurrentYear] = useState(defaultYear)

  const { data, isLoading, error } = useQuery(
    ['portfolioAnalysis', id, currentYear],
    async () => {
      const res = await getPortfolioAnalysis({
        id,
        end_date: `${currentYear}-12-31`,
      })
      return res.data
    },
    {
      enabled: !!id && !!currentYear,
      staleTime: Infinity,
    },
  )

  useEffect(() => {
    if (!isLoading && data) {
      setAnalysis(data)
    }
  }, [isLoading, data])

  useEffect(() => {
    setAnalysis(data || portfolio_analysis)
  }, [id])

  useEffect(() => {
    const year = years.length > 0 ? years[0] : defaultYear
    setCurrentYear(year)
  }, [returns_table])

  return (
    <div className="portfolio-analysis-container">
      <YearRow>
        <KendoDropDown
          items={years}
          onChange={({ target }) => {
            setCurrentYear(target.value)
          }}
          style={{ width: '250px' }}
          value={currentYear}
        />
      </YearRow>
      {isLoading && <LinearProgress />}
      <PraTable
        loading={isLoading}
        is_fof_share_class={is_fof_share_class}
        pra_data={analysis}
        short={short}
        table_props={{ scrollable: 'scrollable' }}
      />
    </div>
  )
}
