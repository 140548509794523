/* eslint-disable react/display-name */
import { Grid, Group } from "@mantine/core"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import Toolbar from "@material-ui/core/Toolbar"
import MenuIcon from "@material-ui/icons/Menu"
import React from "react"
import { Link } from "react-router-dom"

import NotificationButton from "../../components/misc/NotificationButton"
import { GlobalSearch } from "../../components/selectors"
import AvatarMenu from "./AvatarMenu"
import GlobalFundSearch from "../../components/selectors/GlobalFundSearch"

// import PropTypes from "prop-types";

export default ({
  search_list,
  toggleDrawer,
}) => {

  return (
    <>
      <Toolbar className="app-bar-toolbar">
        <Grid align="center">
          <Hidden mdDown>
            <Grid.Col span={4}>
              <Link
                className="logo-link"
                to="/"
              >
                <img
                  alt="antaractica logo"
                  className="logo"
                  src="/landscape_logo.svg"
                />
              </Link>
            </Grid.Col>
          </Hidden>
          <Hidden lgUp>
            <Grid.Col span={4}>
              <IconButton
                className="mobile-menu-button"
                disableRipple
                edge="start"
                onClick={() => {
                  toggleDrawer(true)
                }}
              >
                <MenuIcon />
              </IconButton>
            </Grid.Col>
          </Hidden>
          <Grid.Col span={4}>
            {
              search_list && (
                <GlobalFundSearch
                  items={search_list}
                  searchFields={["name", "isin", "underlying_name", "principal_name"]}
                />
              )
            }
          </Grid.Col>
          <Grid.Col span={4} >
            <Group
              align="center"
              justify="flex-end"
            >
              <NotificationButton />
              <AvatarMenu />
            </Group>
          </Grid.Col>
        </Grid>
      </Toolbar>
    </>
  )
}
