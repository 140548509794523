import React, { useState, useEffect } from "react";
import { dateTimeManager } from "../../utils";
import Button from "@material-ui/core/Button";

import { Formik, Form, FieldArray } from "formik";
import { getDocumentType } from "../../services";
import {
  DropzoneForm,
  TextInput,
  DatePicker,
  BulkDatePicker,
  BulkSecurityList,
  SecurityList,
  Combobox,
  BulkComboBox,
} from "../../components";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { bulkInsertDocuments } from "../../services";
import { useSecurities } from "../../hooks";

const matchAaapCell = (documentName, cellList) => {
  const [cellName, , classLetter] = documentName.split(" ");
  const filtered = cellList.filter((o) => o.name.includes(cellName));
  if (filtered.length > 0) {
    const identified = filtered.filter((o) =>
      o.name.includes(`Class ${classLetter}`)
    );
    if (identified.length > 0) {
      return identified[0];
    }
  }
  return "";
};

export default function BulkDocuments() {
  const [documentTypes, setDocumentTypes] = useState([]);
  const [uploading, setUploading] = useState(false);

  const { search_list: searchList, aaap_cell_list: aaapList } = useSecurities();

  const assignCellsToDocs = ({ setFieldValue, values }) => {
    let parsedDocs = [...values.documents];
    for (let i = 0; i < parsedDocs.length; i++) {
      let doc = parsedDocs[i];
      doc.security = matchAaapCell(doc.name, aaapList);
    }
    setFieldValue("documents", parsedDocs);
  };

  const submitDocs = async (data, { resetForm }) => {
    setUploading(true);
    const { documents: docs } = data;
    let payload = new FormData();
    let names = [],
      story_types = [],
      as_of_dates = [],
      securities = [];
    for (let i = 0; i < docs.length; i++) {
      const element = docs[i];
      const { name, attachment, story_type, as_of_date, security } = element;
      const { id = "" } = security || {};
      names.push(name);
      story_types.push(story_type);
      as_of_dates.push(dateTimeManager.jsToDateTimeDb(as_of_date));
      securities.push(id);
      payload.append("attachments[]", attachment);
    }
    payload.append("names[]", JSON.stringify(names));
    payload.append("story_types[]", JSON.stringify(story_types));
    payload.append("as_of_dates[]", JSON.stringify(as_of_dates));
    payload.append("securities[]", JSON.stringify(securities));

    try {
      await bulkInsertDocuments(payload);
      resetForm();
    } catch (error) {}
    setUploading(false);
  };

  const fetchDocTypes = async () => {
    try {
      const response = await getDocumentType();
      setDocumentTypes(response.data.map((o) => o.name));
    } catch (error) {}
  };

  useEffect(() => {
    fetchDocTypes();
  }, []);

  return (
    <div className="bulk-upload-route">
      <Formik
        initialValues={{
          documents: [],
          global_security: "",
          global_doc_type: "Letters and Risk reports",
          global_date: dateTimeManager.previousMonthEnd(),
        }}
        onSubmit={submitDocs}
      >
        {({ values, setFieldValue }) => {
          const globalDisabled = values.documents.length === 0;
          return (
            <Form>
              <div className="setup-container">
                <div className="document-drop">
                  <Typography align="center" variant="h6">
                    {" "}
                    Documents{" "}
                  </Typography>
                  <DropzoneForm
                    name="documents"
                    label={"Drag'n'drop documents here. Or click to select"}
                    fields={{
                      as_of_date: dateTimeManager.previousMonthEnd(),
                      story_type: "Letters and Risk reports",
                      security: "",
                    }}
                    limit={60}
                  />
                </div>
                <div className="global-selectors">
                  <Typography align="center" variant="h6">
                    {" "}
                    Global settings{" "}
                  </Typography>
                  <BulkSecurityList
                    bulkArray="documents"
                    bulkField="security"
                    items={searchList}
                    placeholder="Fund"
                    name="global_security"
                    textField="name"
                    dataItemKey="id"
                    noLink={true}
                    searchFields={[
                      "name",
                      "isin",
                      "underlying_name",
                      "principal_name",
                    ]}
                    className="kendo-virtual-combobox global-search"
                    disabled={globalDisabled}
                  />
                  <BulkComboBox
                    bulkArray="documents"
                    bulkField="story_type"
                    name={`global_doc_type`}
                    placeholder="Document Type"
                    items={documentTypes}
                    disabled={globalDisabled}
                  />
                  <BulkDatePicker
                    name="global_date"
                    bulkArray="documents"
                    bulkField="as_of_date"
                    disabled={globalDisabled}
                  />
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        assignCellsToDocs({ setFieldValue, values })
                      }
                    >
                      Assign AAAP cells [For AAAP uploads only]
                    </Button>
                  </div>
                </div>
              </div>
              {!globalDisabled && (
                <FieldArray
                  name="documents"
                  render={(arrayHelpers) => (
                    <Paper>
                      <div className="document-table-container">
                        <Typography variant="h6" align="center">
                          Documents to upload
                        </Typography>
                        {values.documents.map((doc, index) => (
                          <div key={index} className="document-row">
                            <TextInput
                              placeholder="Name"
                              name={`documents.${index}.name`}
                              autoComplete="off"
                            />
                            <SecurityList
                              items={searchList}
                              placeholder="Fund"
                              name={`documents.${index}.security`}
                              textField="name"
                              dataItemKey="id"
                              noLink={true}
                              searchFields={[
                                "name",
                                "isin",
                                "underlying_name",
                                "principal_name",
                              ]}
                              className="kendo-virtual-combobox global-search"
                            />
                            <Combobox
                              name={`documents.${index}.story_type`}
                              items={documentTypes}
                            />
                            <DatePicker
                              name={`documents.${index}.as_of_date`}
                            />
                          </div>
                        ))}
                        <div className="submit-row">
                          <Button
                            type="submit"
                            variant="contained"
                            disabled={uploading}
                          >
                            {!uploading ? "Upload" : "Uploading..."}{" "}
                          </Button>
                        </div>
                      </div>
                    </Paper>
                  )}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
