/* eslint-disable max-len */
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import { useSecurities } from '../../../hooks'
import convertObjectKeysToSnakeCase from '../../../utils/convertObjectKeysToSnake'
import convertSnakeToCamel from '../../../utils/convertSnakeToCamel'
import {
  convertValueToDecimal,
  convertValueToPercentage
} from '../../../utils/numberFormatConvertors'
import { setFormPropertyIfCondition } from '../../../utils/yup-utils'
import {
  benchmarkConstraintsInputName,
  cardinalityConstraintsInputName,
  defaultBenchmarkValues,
  defaultCardinalityValues,
  defaultGroupValues,
  defaultOptimiserFormValues,
  groupConstraintsInputName,
} from '../form-default-values'

const mapAssets = (assets, searchList) => (assets ? assets.map((asset) => {
  const foundAsset = searchList.find((it) => it.name === asset.name)
  return foundAsset ? { ...asset, name: { name: foundAsset.name, type: foundAsset.type } } : asset
}) : null)

const convertObjectFields = (object, fields, converter) => {
  return fields.reduce((acc, field) => ({
    ...acc,
    [field]: converter(acc[field]),
  }), object)
}

const convertArrayItems = (items, fields, converter) => items ? items.map(item => convertObjectFields(item, fields, converter)) : null

const convertAssetWeightsFromPercToDecimal = (assets) => convertArrayItems(assets, ['lowerBound', 'suggestedValue', 'upperBound'], convertValueToDecimal)
const convertAssetWeightsFromDecimalToPerc = (assets) => convertArrayItems(assets, ['lowerBound', 'suggestedValue', 'upperBound'], convertValueToPercentage)
const convertGrpConstraintsWeightFromPercToDecimal = (grpConstraints) => convertArrayItems(grpConstraints, ['lowerBound', 'upperBound'], convertValueToDecimal)
const convertGrpConstraintsWeightFromDecimalToPerc = (grpConstraints) => convertArrayItems(grpConstraints, ['lowerBound', 'upperBound'], convertValueToPercentage)
const convertBenchmarkConstraintsWeightFromDecimalToPerc = (benchmarkConstraints) => convertArrayItems(benchmarkConstraints, ['alphaLowerBound', 'alphaUpperBound'], convertValueToPercentage)
const convertBenchmarkConstraintsWeightFromPercToDecimal = (benchmarkConstraints) => convertArrayItems(benchmarkConstraints, ['alphaLowerBound', 'alphaUpperBound'], convertValueToDecimal)

const convertDate = (dateStr) => (dateStr ? moment(dateStr).toDate() : null)

const usePortfolioOptimiserSerialiser = () => {
  const { search_list: searchList } = useSecurities()

  const deserialiseOptimiserRunData = (res) => {
    if (!res) return {}

    const cardinalityConstraints = convertSnakeToCamel(res?.data?.input?.cardinality_constraints || {})
    const data = convertSnakeToCamel(res?.data || {})
    let { input = {} } = data
    const {
      assets, performanceEndDate, performanceStartDate,
    } = input

    input.assets = convertAssetWeightsFromDecimalToPerc(mapAssets(assets, searchList))
    input.performanceStartDate = convertDate(performanceStartDate)
    input.performanceEndDate = convertDate(performanceEndDate)

    input = {
      ...defaultOptimiserFormValues,
      ...input,
    }

    if (input[groupConstraintsInputName].length === 0 && !input.enableGroupConstraints) {
      input[groupConstraintsInputName].push(defaultGroupValues)
    }

    if (input[benchmarkConstraintsInputName].length === 0 && !input.enableBenchmarkConstraints) {
      input[benchmarkConstraintsInputName].push(defaultBenchmarkValues)
    }

    if (!cardinalityConstraints || Object.keys(cardinalityConstraints).length === 0) {
      input[cardinalityConstraintsInputName] = defaultCardinalityValues
    }

    if (input.enableGroupConstraints && input[groupConstraintsInputName]) {
      input[groupConstraintsInputName] = convertGrpConstraintsWeightFromDecimalToPerc(input[groupConstraintsInputName])
    }

    if (input.enableBenchmarkConstraints && input[benchmarkConstraintsInputName]) {
      input[benchmarkConstraintsInputName] = convertBenchmarkConstraintsWeightFromDecimalToPerc(input[benchmarkConstraintsInputName])
    }

    if (input.riskFreeRate) {
      input.riskFreeRate = convertValueToPercentage(input.riskFreeRate)
    }

    const deserialisedData = {
      ...data,
      input,
    }

    return deserialisedData
  }

  const serialiseOptimiserRunData = (initialFormData) => {
    const formData = { ...initialFormData }
    setFormPropertyIfCondition({
      conditionProperty: 'enableGroupConstraints', conditionValue: false, formData, newValue: [], property: groupConstraintsInputName,
    })
    setFormPropertyIfCondition({
      conditionProperty: 'enableBenchmarkConstraints', conditionValue: false, formData, newValue: [], property: benchmarkConstraintsInputName,
    })
    setFormPropertyIfCondition({
      conditionProperty: 'enableCardinalityConstraints', conditionValue: false, formData, newValue: null, property: cardinalityConstraintsInputName,
    })

    if (!formData.enableBlackLittermanView) {
      formData.assets = formData.assets?.map((asset) => ({ ...asset, blackLittermanViews: null }))
    }

    if (!formData.enableGroupConstraints) {
      formData.assets = formData.assets?.map((asset) => ({ ...asset, group: null }))
    } else {
      formData[groupConstraintsInputName] = convertGrpConstraintsWeightFromPercToDecimal(formData[groupConstraintsInputName])
    }

    if (formData?.performanceStartDate) {
      formData.performanceStartDate = moment(formData.performanceStartDate).format('YYYY-MM-DD')
    }

    if (formData?.performanceEndDate) {
      formData.performanceEndDate = moment(formData.performanceEndDate).format('YYYY-MM-DD')
    }

    if (formData?.assets) {
      formData.assets = formData.assets.map((asset) => ({ ...asset, name: asset.name.name }))
      formData.assets = convertAssetWeightsFromPercToDecimal(formData.assets)
    }

    if (formData?.enableBenchmarkConstraints) {
      formData[benchmarkConstraintsInputName] = convertBenchmarkConstraintsWeightFromPercToDecimal(formData[benchmarkConstraintsInputName])
    }

    if (formData?.riskFreeRate) {
      formData.riskFreeRate = convertValueToDecimal(formData.riskFreeRate)
    }

    formData.portfolioOptimiserRunId = uuidv4()

    return convertObjectKeysToSnakeCase(formData)
  }

  return { deserialiseOptimiserRunData, serialiseOptimiserRunData }
}

export default usePortfolioOptimiserSerialiser
