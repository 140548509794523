import React, { useState } from "react";
import PortfolioReport from "./reportContainer";
import { getInternalReport } from "../../services";
import { dateTimeManager, buildFileFromResponse } from "../../utils";
import { useSecurities } from "../../hooks";

export default function ReportWrapper() {
  const { webfolio_list: portfolioList } = useSecurities();
  const [analysisInput, setAnalysisInput] = useState({
    report_type: "PRA",
    end_date: dateTimeManager.defaultEndDate(),
    portfolio: null,
    hide_unused_strategies: false,
  });
  const [analysis, setAnalysis] = useState({});
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [showInputs, setShowInputs] = useState(true);

  const updateSelector = ({ value, name }) => {
    setLoaded(false);
    setAnalysisInput((i) => ({ ...i, [name]: value }));
  };

  const getReportInputs = () => {
    const { portfolio, end_date, hide_unused_strategies } = analysisInput || {};
    const { id } = portfolio || {};
    const inputs = {
      portfolio: id,
      report_type: "PRA",
      end_date: dateTimeManager.jsToDb(end_date),
      hide_unused_strategies,
    };
    return inputs;
  };

  const onGenerateReport = async () => {
    setLoading(true);
    const inputs = getReportInputs();
    try {
      const response = await getInternalReport(inputs);
      setAnalysis(response.data);
      setLoaded(true);
      setShowInputs(false);
    } catch (error) {}
    setLoading(false);
  };

  const onGenerateRoC = async () => {
    setLoading(true);
    const inputs = {
      ...getReportInputs(),
      report_type: "PRA RoC",
      responseType: "blob",
    };
    try {
      const response = await getInternalReport(inputs);
      buildFileFromResponse(response);
    } catch (error) {}
    setLoading(false);
  };

  return (
    <div className="portfolio-analysis-route">
      <PortfolioReport
        webfolio_list={portfolioList}
        work_ready={portfolioList.length !== 0}
        updateSelector={updateSelector}
        onGenerateReport={onGenerateReport}
        onGenerateRoC={onGenerateRoC}
        analysis_inputs={analysisInput}
        internal_analysis={analysis}
        loading={loading}
        loaded={loaded}
        showInputs={showInputs}
        setShowInputs={setShowInputs}
      />
    </div>
  );
}
