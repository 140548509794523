const BUILD_ENV =
  process.env.NODE_ENV !== 'production'
    ? 'development'
    : process.env.REACT_APP_VERCEL_ENV
export const IS_PROD_ENV = BUILD_ENV === 'production'
export const IS_PREVIEW_ENV = BUILD_ENV === 'preview'
export const IS_DEV_ENV = BUILD_ENV === 'development'
export const HUBSPOT_PROXY_URL = IS_DEV_ENV
  ? 'http://localhost:5200/api/hubspot'
  : `${window.location.origin}/api/hubspot`
export const HUBSPOT_BLOG_POST_ENDPOINT =
  process.env.REACT_APP_HS_BLOG_POSTS_ENDPOINT
export const HIGHLIGHTS_HS_POST_ID = process.env.REACT_APP_HIGHLIGHTS_HS_POST_ID
export const DISABLE_PERFORMANCE_QUALITY_INDICATOR =
  process.env.REACT_APP_DISABLE_PERFORMANCE_QUALITY_INDICIATOR === 'true'
export const FUND_DOCS_TYPES = JSON.parse(process.env.REACT_APP_FUND_DOCS_TYPES)
export const MANAGER_HOLDINGS_SHORTNAME_MAP = JSON.parse(
  process.env.REACT_APP_MANAGER_HOLDINGS_SHORTNAME_MAP,
)
export const OPTIMISER_FORM_DEFAULT_VALUES = JSON.parse(
  process.env.REACT_APP_OPTIMISER_DEFAULT_VALUE,
)
export const ASSET_DETAILS_OPTIMISED_QUERIES_ENABLED = JSON.parse(
  process.env.REACT_APP_ASSET_DETAILS_OPTIMISED_QUERIES_ENABLED,
)
export default BUILD_ENV
