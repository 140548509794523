import React from 'react'
import { Document } from '../../hooks/useFetchAaapDocs'
import { Box, Typography } from '@material-ui/core'
import { DocumentList } from '../myDocuments'

interface FundDocumentListBlockProps {
  header: string
  loading?: boolean
  onDocumentClick: ({
    id,
    name,
    story_type,
  }: {
    id: number
    name: string
    story_type: string
  }) => void
  documents: Document[]
}

const FundDocumentListBlock: React.FC<FundDocumentListBlockProps> = ({
  loading,
  header,
  onDocumentClick,
  documents,
}) =>
  loading || (!loading && documents?.length > 0) ? (
    <Box mb={6}>
      <Typography variant="h6" className="asset-detail-subheader">
        {header}
      </Typography>
      <DocumentList
        loading={loading}
        documents={documents}
        onDocumentClick={onDocumentClick}
      />
    </Box>
  ) : null

export default FundDocumentListBlock
